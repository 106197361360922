<template>
  <b-container fluid>
    <b-row>
      <b-form @submit.prevent="saveData()">
        <b-col sm="12">
          <card>
            <template v-slot:headerTitle>
              <h4 class="card-title"><b>NUEVA PERSONA</b></h4>
            </template>
            <h4 class="px-4 py-4"><b>Información Básica</b></h4>
            <div class="row px-5">
              <div class="col-md-6">
                <div>
                  <label for=" " class="form-label">Tipo de Documento</label>
                  <b-form-select
                    required
                    value-field="id"
                    text-field="descripcion"
                    v-model="persona.TipoDocumentoId"
                    :options="listaTipoDocumento"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null" disabled>
                        -- Seleccione una opción --</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3">
                  <label for=" " class="form-label">Numero de Documento</label>
                  <input
                    required
                    v-model="persona.NumeroDocumento"
                    placeholder="Escriba el Numero de Documento..."
                    type="text"
                    class="form-control"
                    id=" "
                  />
                </div>
              </div>

              <div class="col-md-6">
                <div class="mb-3">
                  <label for=" " class="form-label">Primer nombre</label>
                  <input
                    required
                    v-model="persona.Nombres"
                    placeholder="Escriba el Primer nombre..."
                    type="text"
                    class="form-control"
                    id=" "
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3">
                  <label for=" " class="form-label"
                    >Segundo nombre (opcional)</label
                  >
                  <input
                    v-model="persona.SegundoNombre"
                    placeholder="Escriba el Segundo Nombre..."
                    type="text"
                    class="form-control"
                    id=" "
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3">
                  <label for=" " class="form-label">Primer apellido</label>
                  <input
                    required
                    v-model="persona.Apellidos"
                    placeholder="Escriba el primer apellido..."
                    type="text"
                    class="form-control"
                    id=" "
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3">
                  <label for=" " class="form-label"
                    >Segundo apellido (opcional)</label
                  >
                  <input
                    v-model="persona.SegundoApellido"
                    placeholder="Escriba el segundo apellido..."
                    type="text"
                    class="form-control"
                    id=" "
                  />
                </div>
              </div>

              <div class="col-md-6">
                <div class="mb-3">
                  <label for=" " class="form-label">Fecha Nacimiento</label>
                  <input
                    required
                    v-model="persona.FechaNacimiento"
                    type="date"
                    class="form-control"
                    id=" "
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3">
                  <label for=" " class="form-label"
                    >Fecha de Contratación</label
                  >
                  <input
                    required
                    v-model="persona.FechaContratacion"
                    type="date"
                    class="form-control"
                    id=" "
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div>
                  <label for=" " class="form-label">Rol en Sistema</label>
                  <b-form-select
                    required
                    v-model="persona.RolId"
                    text-field="descripcion"
                    value-field="id"
                    :options="listaRol"
                  >
                    <template #first>
                      <b-form-select-option selected :value="null" disabled
                        >-- selecciona una opción --</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </div>
              </div>
              <div class="col-md-6">
                <div>
                  <label for=" " class="form-label">Estado Civil</label>
                  <b-form-select
                    required
                    v-model="persona.EstadoCivilId"
                    text-field="descripcion"
                    value-field="id"
                    :options="listaEstadoCivil"
                  >
                    <template #first>
                      <b-form-select-option selected :value="null" disabled
                        >-- selecciona una opción --</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </div>
              </div>
              <div class="col-md-6 mt-3 mb-4">
                <div>
                  <label for=" " class="form-label">Nivel de Reportes</label>
                  <b-form-select
                    required
                    v-model="selectedNivelesReporte"
                    text-field="text"
                    value-field="value"
                    :select-size="8"
                    :options="listaNivelReporte"
                    multiple
                  >
                    <template #first>
                      <b-form-select-option selected :value="null" disabled
                        >-- selecciona una opción --</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                  <div class="mt-3">
                    Niveles seleccionados:
                    <strong>{{
                      selectedNivelesReporte.length
                        ? selectedNivelesReporte
                        : "Ninguno"
                    }}</strong>
                  </div>
                </div>
              </div>
            </div>
          </card>
        </b-col>
        <b-col sm="12">
          <card>
            <template v-slot:headerTitle>
              <h4 class="card-title"><b>Datos de Contacto</b></h4>
            </template>

            <div class="row px-5 mt-3">
              <div class="col-md-6">
                <div>
                  <label for=" " class="form-label">País</label>
                  <b-form-select
                    required
                    @change.native="getDepartmentoPaisList(persona.PaisId)"
                    v-model="persona.PaisId"
                    value-field="id"
                    text-field="descripcion"
                    :options="listaPaises"
                  >
                    <template #first>
                      <b-form-select-option selected :value="null" disabled
                        >-- selecciona una opción --</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3">
                  <label for=" " class="form-label">Dirección</label>
                  <input
                    required
                    v-model="persona.Direccion"
                    placeholder="Escriba la Dirección..."
                    type="text"
                    class="form-control"
                    id=" "
                  />
                </div>
              </div>

              <div class="col-md-6">
                <div>
                  <label for=" " class="form-label">Departamento/Estado</label>
                  <b-form-select
                    required
                    @change.native="
                      actualizaMunicipios(persona.DepartamentoPaisId)
                    "
                    value-field="id"
                    text-field="descripcion"
                    v-model="persona.DepartamentoPaisId"
                    :options="listaDepartamentos"
                  >
                    <template #first>
                      <b-form-select-option selected :value="null" disabled
                        >-- selecciona una opción --</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3">
                  <label for=" " class="form-label">Número Fijo</label>
                  <input
                    required
                    v-model="persona.TelefonoFijo"
                    placeholder="Escriba el Numero Fijo..."
                    type="text"
                    class="form-control"
                    id=" "
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div>
                  <label for=" " class="form-label">Ciudad/Municipio</label>
                  <b-form-select
                    v-model="persona.MunicipioId"
                    required
                    value-field="id"
                    text-field="descripcion"
                    :options="listaMunicipios"
                  >
                    <template #first>
                      <b-form-select-option selected :value="null" disabled
                        >-- selecciona una opción --</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3">
                  <label for=" " class="form-label"
                    >Número Móvil (opcional)</label
                  >
                  <input
                    v-model="persona.TelefonoMovil"
                    placeholder="Escriba el Numero Móvil..."
                    type="text"
                    class="form-control"
                    id=" "
                  />
                </div>
              </div>
            </div>
          </card>
        </b-col>
        <b-col sm="12">
          <card>
            <template v-slot:headerTitle>
              <h4 class="card-title"><b>Datos de Seguridad</b></h4>
            </template>

            <div class="row px-5 mt-3">
              <div class="col-md-6">
                <div>
                  <label for=" " class="form-label">Correo Electrónico</label>
                  <input
                    @blur="verificaCorreo(persona.Correo)"
                    required
                    v-model="persona.Correo"
                    placeholder="Escriba el Correo Electrónico..."
                    type="email"
                    class="form-control"
                    id=" "
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div>
                  <label for=" " class="form-label"
                    >Correo Notificaciones</label
                  >
                  <input
                    required
                    v-model="persona.CorreoNotificaciones"
                    placeholder="Escriba el Correo Electrónico..."
                    type="email"
                    class="form-control"
                    id=" "
                  />
                </div>
              </div>
              <!-- <div class="col-xl-6">
              <div class="mb-3">
                <label for=" " class="form-label">Contraseña para Reportes</label>
                <input placeholder="Escriba la Contraseña para Reportes..." type="text" class="form-control" id=" " />
              </div>
            </div> -->
              <!-- <div class="col-xl-6">
              <div>
                <label for=" " class="form-label">Contraseña</label>
                <input placeholder="Escriba la Contraseña..." type="text" class="form-control" id=" " />
              </div>
            </div> -->
              <div class="col-md-6">
                <div class="mb-3">
                  <label for=" " class="form-label">Estado Usuario</label>
                  <b-form-select
                    required
                    value-field="id"
                    text-field="text"
                    v-model="persona.EstadoUsuario"
                    :options="estadoUsuario"
                  >
                    <template #first>
                      <b-form-select-option
                        selected
                        value-field="id"
                        text-field="text"
                        :value="null"
                        disabled
                        >-- selecciona una opción --</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </div>
              </div>
            </div>
          </card>
        </b-col>
        <b-col sm="12">
          <card>
            <template v-slot:headerTitle>
              <h4 class="card-title"><b>Datos de Empresa</b></h4>
            </template>

            <div class="row px-5 mt-3">
              <div class="col-md-6">
                <div class="mb-3">
                  <label for=" " class="form-label">Empresa</label>
                  <b-form-select
                    :disabled="usuario.rol.nivel < 4 ? true : false"
                    required
                    @change.native="getSucursalEmpresa(persona.EmpresaId)"
                    v-model="persona.EmpresaId"
                    text-field="nombre"
                    value-field="id"
                    :options="ListEmpresas"
                  >
                    <template #first>
                      <b-form-select-option selected :value="null" disabled
                        >-- selecciona una opción --</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3">
                  <label for=" " class="form-label">Area</label>
                  <!-- <b-form-select></b-form-select> -->
                  <b-form-select
                    required
                    @change.native="getCargos(persona.EmpresaId)"
                    text-field="descripcion"
                    value-field="id"
                    v-model="persona.AreaId"
                    :options="listArea"
                  >
                    <template #first>
                      <b-form-select-option selected :value="undefined" disabled
                        >-- selecciona una opción --</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </div>
              </div>

              <div class="col-md-6">
                <div class="mb-3">
                  <label for=" " class="form-label">Sucursal</label>
                  <b-form-select
                    required
                    @change.native="getDepartamentoSucursal(persona.SucursalId)"
                    text-field="descripcion"
                    value-field="id"
                    v-model="persona.SucursalId"
                    :options="listaSucursales"
                  >
                    <template #first>
                      <b-form-select-option selected :value="undefined" disabled
                        >-- selecciona una opción --</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3">
                  <label for=" " class="form-label">Cargo</label>
                  <div
                    style="
                      background: #102444;
                      padding: 0rem;
                      border-radius: 0.3rem;
                    "
                  >
                    <v-select
                      required
                      class="style-chooser"
                      v-model="persona.CargoId"
                      :options="listCargos"
                      label="nombre"
                      placeholder="-- selecciona una opción --"
                    ></v-select>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3">
                  <label for=" " class="form-label">Departamento</label>
                  <b-form-select
                    required
                    @change.native="getAreaDepartamento(persona.DepartamentoId)"
                    text-field="descripcion"
                    value-field="id"
                    v-model="persona.DepartamentoId"
                    :options="ListDepartamento"
                  >
                    <template #first>
                      <b-form-select-option selected :value="undefined" disabled
                        >-- selecciona una opción --</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3">
                  <label for=" " class="form-label"
                    >Jefe Directo (opcional)</label
                  >
                  <b-form-select v-model="persona.JefeDirectoId">
                    <template>
                      <b-form-select-option
                        selected
                        :value="undefined"
                        disabled
                      >
                        -- selecciona una opción --</b-form-select-option
                      >
                    </template>
                    <b-form-select-option
                      :value="it.id"
                      v-for="it of listaPersonas"
                      :key="it.id"
                      >{{
                        it.nombres + " " + it.apellidos
                      }}</b-form-select-option
                    >
                  </b-form-select>
                </div>
              </div>
            </div>

            <!-- información laboral -->
            <!-- {{ persona }} -->
            <b-col sm="12">
              <card>
                <template v-slot:headerTitle>
                  <h4 class="card-title"><b>Información Laboral</b></h4>
                </template>
                <div class="row px-5 mt-3">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label for=" " class="form-label">Tipo de contrato</label>
                      <b-form-select
                        required
                        v-model="persona.TipoContrato"
                        text-field="nombre"
                        value-field="id"
                        :options="tipoContrato"
                      >
                        <template #first>
                          <b-form-select-option :value="null" selected disabled
                            >-- selecciona una opción --</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label for=" " class="form-label">Salario integral</label>
                      <!-- <b-form-select></b-form-select> -->
                      <b-form-select
                        required
                        v-model="persona.SalarioIntegral"
                        :options="salarioIntegral"
                      >
                        <template #first>
                          <b-form-select-option selected :value="null" disabled
                            >-- selecciona una opción --</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </div>
                  </div>
                  <div class="col-md-6" v-if="persona.SalarioIntegral == 'Si'">
                    <div class="mb-3">
                      <label for=" " class="form-label">Factor salarial</label>
                      <input
                        required
                        v-model="persona.FactorSalarial"
                        placeholder=""
                        type="number"
                        class="form-control"
                        id=" "
                      />
                    </div>
                  </div>
                  <div class="col-md-6" v-if="persona.SalarioIntegral == 'Si'">
                    <div class="mb-3">
                      <label for=" " class="form-label"
                        >Factor prestacional</label
                      >
                      <input
                        required
                        v-model="persona.FactorPrestacional"
                        placeholder=""
                        type="number"
                        class="form-control"
                        id=" "
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label for=" " class="form-label"
                        >Auxilio Transporte</label
                      >
                      <!-- <b-form-select></b-form-select> -->
                      <b-form-select
                        required
                        v-model="persona.AuxilioTransporte"
                        :options="ListaauxilioTransporte"
                      >
                        <template #first>
                          <b-form-select-option selected :value="null" disabled
                            >-- selecciona una opción --</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label for=" " class="form-label">Salario</label>
                      <input
                        required
                        v-model="persona.Salario"
                        placeholder=""
                        type="number"
                        class="form-control"
                        id=" "
                      />
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="mb-3">
                      <label for=" " class="form-label"
                        >Tipo de trabajador</label
                      >
                      <b-form-select
                        required
                        v-model="persona.TipoTrabajador"
                        text-field="nombre"
                        value-field="id"
                        :options="tipoTrabajador"
                      >
                        <template #first>
                          <b-form-select-option selected disabled
                            >-- selecciona una opción --</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label for=" " class="form-label"
                        >Subtipo de trabajador</label
                      >
                      <b-form-select
                        required
                        v-model="persona.SubtipoTrabajador"
                        text-field="nombre"
                        value-field="id"
                        :options="subtipoTrabajador"
                      >
                        <template #first>
                          <b-form-select-option selected :value="null" disabled
                            >-- selecciona una opción --</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label for=" " class="form-label"
                        >Frecuencia de pago (opcional)</label
                      >
                      <b-form-select
                        text-field="nombre"
                        value-field="id"
                        v-model="persona.FrecuenciaPago"
                        :options="frecuenciaPago"
                      >
                        <template #first>
                          <b-form-select-option selected :value="null" disabled
                            >-- selecciona una opción --</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label for=" " class="form-label"
                        >Alto riesgo (opcional)</label
                      >
                      <b-form-select
                        v-model="persona.AltoRiesgo"
                        value-field="id"
                        text-field="nombre"
                        :options="altoRiesgo"
                      >
                        <template>
                          <b-form-select-option selected :value="null" disabled
                            >-- selecciona una opción --</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </div>
                  </div>
                </div>
              </card>
            </b-col>

            <!-- metodo de pago -->
            <b-col sm="12">
              <card>
                <template v-slot:headerTitle>
                  <h4 class="card-title"><b>Método de pago</b></h4>
                </template>
                <div class="row px-5 mt-3">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label for=" " class="form-label">Método de pago</label>
                      <b-form-select
                        v-model="persona.MetodoPago"
                        required
                        text-field="nombre"
                        value-field="id"
                        :options="MetodosDePago"
                      >
                        <template #first>
                          <b-form-select-option selected :value="null" disabled
                            >-- selecciona una opción --</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label for=" " class="form-label">Banco (opcional)</label>
                      <!-- <b-form-select></b-form-select> -->
                      <b-form-select
                        v-model="persona.Banco"
                        text-field="nombre"
                        value-field="id"
                        :options="ListaBancos"
                      >
                        <template #first>
                          <b-form-select-option selected :value="null" disabled
                            >-- selecciona una opción --</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="mb-3">
                      <label for=" " class="form-label"
                        >Tipo de cuenta (opcional)</label
                      >
                      <b-form-select
                        v-model="persona.TipoCuenta"
                        text-field="tipo"
                        value-field="id"
                        :options="TiposDeCuenta"
                      >
                        <template #first>
                          <b-form-select-option selected :value="null" disabled
                            >-- selecciona una opción --</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="mb-3">
                      <label for=" " class="form-label"
                        >Número de cuenta (opcional)</label
                      >
                      <input
                        placeholder=""
                        v-model="persona.NumeroCuenta"
                        type="text"
                        class="form-control"
                        id=" "
                      />
                    </div>
                  </div>
                </div>
              </card>
            </b-col>

            <!-- AFILIACION -->
            <b-col sm="12">
              <card>
                <template v-slot:headerTitle>
                  <h4 class="card-title"><b>Afiliación</b></h4>
                </template>
                <div class="row px-5 mt-3">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label for=" " class="form-label">EPS</label>
                      <b-form-select
                        v-model="persona.Eps"
                        required
                        text-field="nombre"
                        value-field="id"
                        :options="ListaEps"
                      >
                        <template #first>
                          <b-form-select-option selected :value="null" disabled
                            >-- selecciona una opción --</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label for=" " class="form-label"
                        >Caja de compensación</label
                      >
                      <!-- <b-form-select></b-form-select> -->
                      <b-form-select
                        v-model="persona.CajaCompensacion"
                        required
                        text-field="nombre"
                        value-field="id"
                        :options="ListaFondoCompensacion"
                      >
                        <template #first>
                          <b-form-select-option selected :value="null" disabled
                            >-- selecciona una opción --</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="mb-3">
                      <label for=" " class="form-label"
                        >Fondo de pensiones</label
                      >
                      <b-form-select
                        v-model="persona.FondoPensiones"
                        required
                        text-field="nombre"
                        value-field="id"
                        :options="ListaFondoPension"
                      >
                        <template #first>
                          <b-form-select-option selected :value="null" disabled
                            >-- selecciona una opción --</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="mb-3">
                      <label for=" " class="form-label"
                        >Fondo de cesantías</label
                      >
                      <b-form-select
                        v-model="persona.FondoCesantias"
                        required
                        text-field="nombre"
                        value-field="id"
                        :options="ListaFondoCesantias"
                      >
                        <template #first>
                          <b-form-select-option selected :value="null" disabled
                            >-- selecciona una opción --</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </div>
                  </div>
                </div>
              </card>
            </b-col>

            <div class="text-right px-5 pb-4">
              <button class="mx-1 btn px-4 py-2 btn-primary">
                Guardar Persona
              </button>
              <button
                class="mx-1 btn px-4 py-2 btn-secondary"
                @click="$router.push('/helex/Persona')"
              >
                Cancelar Registro
              </button>
            </div>
          </card>
        </b-col>
      </b-form>
    </b-row>
  </b-container>
</template>
<script>
import Vue from "vue";
import vSelect from "vue-select";

Vue.component("v-select", vSelect);

const Swal = require("sweetalert2");
export default {
  name: "PersonaAgregar",
  data() {
    return {
      listaTipoDocumento: [],
      listaRol: [],
      listaEstadoCivil: [],
      listaNivelReporte: [],
      selectedNivelesReporte: [],
      listaTipoSalario: [
        { id: 1, titulo: "Salario fijo" },
        { id: 2, titulo: "Salario variable" },
      ],
      persona: {
        Id: null,
        CargoId: null,
        AreaId: null,
        DepartamentoId: null,
        SucursalId: null,
        PersonaId: null,

        NumeroDocumento: null,
        Nombres: null,
        SegundoNombre: null,
        Apellidos: null,
        SegundoApellido: null,
        Direccion: null,
        TelefonoFijo: null,
        TelefonoMovil: null,
        Correo: null,
        FechaNacimiento: null,
        FechaContratacion: null,
        JefeDirectoId: null,
        EstadoUsuario: null,
        Estado: 1,
        NivelReporte: null,
        EmpresaId: null,
        TipoDocumentoId: null,
        MunicipioId: null,
        DepartamentoPaisId: null,
        PaisId: null,
        RolId: null,
        EstadoCivilId: null,
        CorreoNotificaciones: null,
        ImagenPerfilUrl: "",

        TipoContrato: null,
        SalarioIntegral: null,
        Salario: 0,
        TipoTrabajador: null,
        SubtipoTrabajador: null,
        FrecuenciaPago: null,
        AltoRiesgo: 0,
        AuxilioTransporte: null,
        SabadoLaboral: null,
        NivelRiesgo: null,
        DiasVacacionesAcumulados: null,
        MetodoPago: null,
        Banco: null,
        NumeroCuenta: null,
        TipoCuenta: null,
        Eps: null,
        CajaCompensacion: null,
        FondoPensiones: null,
        TipoSalario: null,
        FondoCesantias: null,
        FactorSalarial: null,
        FactorPrestacional: null,
      },
      listaPaises: [],
      listaDepartamentos: [],
      listaMunicipios: [],
      estadoUsuario: [
        { id: 1, text: "Activo" },
        { id: 0, text: "Inactivo" },
      ],
      usuario: { rol: { nivel: 0 } },
      ListEmpresas: [],
      listaSucursales: [],
      ListDepartamento: [],
      listArea: [],
      listCargos: [],
      listaPersonas: [],
      ListaauxilioTransporte: ["Si", "No"],

      /////////////
      tipoContrato: [
        { id: 1, nombre: "Término Fijo" },
        { id: 2, nombre: "Término Indefinido" },
        { id: 3, nombre: "Obra o Labor" },
        { id: 4, nombre: "Obra Aprendizaje" },
        { id: 5, nombre: "Prácticas o Pasantías" },
      ],
      salarioIntegral: ["Si", "No"],
      tipoTrabajador: [],
      subtipoTrabajador: [],
      altoRiesgo: [
        { id: 0, nombre: "No" },
        { id: 1, nombre: "Si" },
      ],
      frecuenciaPago: [
        { id: 0, nombre: "Quincenal" },
        { id: 1, nombre: "Mensual" },
      ],
      Correo: null,
      MetodosDePago: [],
      ListaBancos: [],
      TiposDeCuenta: [
        { id: 1, tipo: "Ahorros" },
        { id: 2, tipo: "Corriente" },
      ],
      ListaEps: [],
      ListaFondoPension: [],
      ListaFondoCesantias: [],
      ListaFondoCompensacion: [],
    };
  },
  async mounted() {
    this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
    this.persona.EmpresaId = this.usuario.empresa.id;
    this.persona.PersonaId = this.usuario.id;
    await this.getTipoDocumento();
    await this.getListaRol();
    await this.getListaEstadoCivil();
    await this.getPaisList();
    await this.getEmpresas();
    await this.getTipoTrabajador();

    await this.getSucursalEmpresa(this.persona.EmpresaId);

    for (let index = 1; index < 16; index++) {
      this.listaNivelReporte.push({ value: index, text: "Nivel " + index });
    }
  },
  methods: {
    async getTipoTrabajador() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Trabajador/ListaTipoTrabajador/",
        });
        this.tipoTrabajador = res;

        res = await this.$store.dispatch("hl_get", {
          path: "Trabajador/ListaSubTipoTrabajador/",
        });
        this.subtipoTrabajador = res;
        res = await this.$store.dispatch("hl_get", {
          path: "Trabajador/MetodoDePago",
        });
        this.MetodosDePago = res;
        res = await this.$store.dispatch("hl_get", {
          path: "Trabajador/ListaNominaBancos",
        });
        this.ListaBancos = res;
        res = await this.$store.dispatch("hl_get", {
          path: "Trabajador/ListaAfiliaciones",
        });
        this.ListaEps = res.filter((x) => x.tipo == "EPS");
        this.ListaFondoPension = res.filter((x) => x.tipo == "PENSION");
        this.ListaFondoCesantias = res.filter((x) => x.tipo == "CESANTIAS");
        this.ListaFondoCompensacion = res.filter(
          (x) => x.tipo == "COMPENSACION"
        );
      } catch (error) {
        this.listCargos = {};
        console.log("err", error);
      }
    },
    async verificaCorreo(email) {
      try {
        let res = null;
        res = await this.$store.dispatch("hl_get", {
          path: "Persona/GetPersonaRecuperar/" + email,
        });
        if (Object.prototype.hasOwnProperty.call(res, "id")) {
          Swal.fire(
            "Atención!",
            "Error, el correo ya se encuentra registrado",
            "error"
          );
          this.persona.Correo = "";
        }
      } catch (error) {
        console.log("error en validar correo", error);
      }
    },
    async saveData() {
      console.log("savedata ", this.persona);
      try {
        let data = {
          Worker: {
            NumeroDocumento: this.persona.NumeroDocumento,
            Nombres: this.persona.Nombres,
            SegundoNombre: this.persona.SegundoNombre,
            Apellidos: this.persona.Apellidos,
            SegundoApellido: this.persona.SegundoApellido,

            Direccion: this.persona.Direccion,
            TelefonoFijo: this.persona.TelefonoFijo,
            TelefonoMovil: this.persona.TelefonoMovil,
            Correo: this.persona.Correo,
            FechaNacimiento: this.persona.FechaNacimiento,
            FechaContratacion: this.persona.FechaContratacion,
            JefeDirectoId: this.persona.JefeDirectoId,
            EstadoUsuario: this.persona.EstadoUsuario,
            Estado: this.persona.EstadoUsuario == 1 ? 1 : 0,
            NivelReporte: JSON.stringify(this.selectedNivelesReporte),
            EmpresaId: this.persona.EmpresaId,
            TipoDocumentoId: this.persona.TipoDocumentoId,
            MunicipioId: this.persona.MunicipioId,
            DepartamentoPaisId: this.persona.DepartamentoPaisId,
            PaisId: this.persona.PaisId,
            RolId: this.persona.RolId,
            EstadoCivilId: this.persona.EstadoCivilId,
            ClaveDinamica: "",
            CorreoNotificaciones: this.persona.CorreoNotificaciones,
            ImagenPerfilUrl: "",

            TipoContrato: this.persona.TipoContrato,
            SalarioIntegral:
              this.persona.SalarioIntegral == "Si" ? true : false,
            Salario: this.persona.Salario,
            TipoTrabajador: this.persona.TipoTrabajador,
            SubtipoTrabajador: this.persona.SubtipoTrabajador,
            FrecuenciaPago: this.persona.FrecuenciaPago,
            AltoRiesgo: this.persona.AltoRiesgo == 1 ? true : false,
            AuxilioTransporte:
              this.persona.AuxilioTransporte == "Si" ? true : false,
            SabadoLaboral: this.persona.SabadoLaboral,
            NivelRiesgo: this.persona.NivelRiesgo,
            DiasVacacionesAcumulados: this.persona.DiasVacacionesAcumulados,
            MetodoPago: this.persona.MetodoPago,
            Banco: this.persona.Banco,
            NumeroCuenta: this.persona.NumeroCuenta,
            TipoCuenta: this.persona.TipoCuenta,
            Eps: this.persona.Eps,
            CajaCompensacion: this.persona.CajaCompensacion,
            FondoPensiones: this.persona.FondoPensiones,
            FondoCesantias: this.persona.FondoCesantias,
            TipoSalario: this.persona.TipoSalario,
            FactorSalarial: this.persona.FactorSalarial,
            FactorPrestacional: this.persona.FactorPrestacional,
            ContrasenaReportes: 1500,
            UltimoIngreso: this.$moment
              .tz(new Date(), "America/Bogota")
              .format("YYYY-MM-DDTHH:mm"),
          },
          Position: {
            Id: "00000000-0000-0000-0000-000000000000",
            CargoId: this.persona.CargoId.id,
            AreaId: this.persona.AreaId,
            DepartamentoId: this.persona.DepartamentoId,
            SucursalId: this.persona.SucursalId,
            PersonaId: this.persona.PersonaId,
          },
        };
        console.log("items ----> ", data);

        let res = await this.$store.dispatch("hl_post", {
          path: "Persona/Create",
          data: data,
        });
        console.log(res);
        if (res) {
          Swal.fire({
            title: "Listo!",
            text: "Registro almacenado correctamente",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
            cancelButtonText: "Cancelar",
          }).then(async (result) => {
            if (result.isConfirmed) {
              // this.$router.push("/helex/Persona");
            }
          });
        }
      } catch (error) {
        console.log("err", error);
      }
    },
    limpiarDataEmpresa() {
      this.persona.SucursalId = undefined;
      this.persona.AreaId = undefined;
      this.persona.CargoId = undefined;
      this.persona.DepartamentoId = undefined;
      this.persona.JefeDirectoId = undefined;
      this.$forceUpdate();
    },
    async getPersonas() {
      //console.log(this.persona.empresa.id);
      try {
        console.log(
          "get personas/////////////////////////////////////////////..."
        );
        let res = await this.$store.dispatch("hl_get", {
          path: "Persona/ListPersona/" + this.persona.EmpresaId,
        });
        console.log("...", res);
        if (res.length > 0) {
          this.listaPersonas = res;
        } else {
          this.listaPersonas = [];
        }
      } catch (error) {
        this.listaPersonas = [];
        console.log("err", error);
      }
    },
    async getCargos(id) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Cargo/ListCargo/" + id,
        });
        this.listCargos = res;

        console.log("get area -----", this.listCargos);
      } catch (error) {
        this.listCargos = {};
        console.log("err", error);
      }
    },
    async getAreaDepartamento(id) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Area/ListAreaDepartamento/" + id,
        });
        this.listArea = res;

        console.log("get area -----", this.listArea);
      } catch (error) {
        this.listArea = {};
        console.log("err", error);
      }
    },
    async getDepartamentoSucursal(id) {
      console.log(id);
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Departamento/ListDepartamentoSucursal/" + id,
        });
        this.ListDepartamento = res;
        console.log("departamentosooooooooooooo ", res);
      } catch (error) {
        this.ListDepartamento = {};
        console.log("err", error);
      }
    },
    async getSucursalEmpresa(id) {
      try {
        console.log("get empresas...");
        let res = await this.$store.dispatch("hl_get", {
          path: "Sucursal/ListSucursalEmpresa/" + id,
        });
        console.log("post sucursal... ", res);
        if (res.length > 0) {
          this.listaSucursales = res;
          this.limpiarDataEmpresa();
          await this.getCargos(id);
          await this.getPersonas();
        } else {
          this.listaSucursales = [];
        }
      } catch (error) {
        this.listaSucursales = [];
        console.log("err", error);
      }
    },
    async getEmpresas() {
      try {
        let res = await this.$store.dispatch("hl_post", {
          path: "Empresa/ListEmpresa/",
        });
        this.ListEmpresas = res;
        await this.getPersonas();
      } catch (error) {
        this.ListEmpresas = {};
        console.log("err", error);
      }
    },
    async actualizaMunicipios(e) {
      console.log("++++++++", e);
      this.listaMunicipios = await this.getMunicipioByDepartamento(e);
      this.$forceUpdate();
    },
    async getMunicipioByDepartamento(id) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Municipio/ListByDepartamento/" + id,
        });
        //console.log("------>", res);
        if (res.length > 0) {
          return res;
          ////console.log(res)
        } else {
          return [];
        }
      } catch (error) {
        console.log("err getDepartmentoPaisList", error);
        return [];
      }
    },
    async getDepartmentoPaisList(id) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "DepartamentoPais/ListByPais/" + id,
        });
        //console.log("------>", res);
        if (res.length > 0) {
          this.listaDepartamentos = res;
        } else {
          this.listaDepartamentos = [];
        }
      } catch (error) {
        console.log("err getDepartmentoPaisList", error);
        this.listaDepartamentos = [];
      }
    },
    async getPaisList() {
      try {
        let res = await this.$store.dispatch("hl_post", { path: "Pais/List" });
        //console.log("------>", res);
        if (res.length > 0) {
          this.listaPaises = res;
          ////console.log(res)
        } else {
          this.listaPaises = [];
        }
      } catch (error) {
        this.listaPaises = [];
        console.log("err", error);
      }
    },
    async getTipoDocumento() {
      try {
        let res = await this.$store.dispatch("hl_post", {
          path: "TipoDocumento/ListTipoDocumento",
        });
        //console.log("------>", res);
        if (res.length > 0) {
          this.listaTipoDocumento = res;
          ////console.log(res)
        } else {
          this.listaTipoDocumento = [];
        }
      } catch (error) {
        this.listaTipoDocumento = [];
        console.log("err", error);
      }
    },
    async getListaRol() {
      try {
        let res = await this.$store.dispatch("hl_post", { path: "Rol/List" });
        //console.log("------>", res);
        if (res.length > 0) {
          this.listaRol = res;
          ////console.log(res)
        } else {
          this.listaRol = [];
        }
      } catch (error) {
        this.listaRol = [];
        console.log("err", error);
      }
    },
    async getListaEstadoCivil() {
      try {
        let res = await this.$store.dispatch("hl_post", {
          path: "EstadoCivil/List",
        });
        //console.log("------>", res);
        if (res.length > 0) {
          this.listaEstadoCivil = res;
          ////console.log(res)
        } else {
          this.listaEstadoCivil = [];
        }
      } catch (error) {
        this.listaEstadoCivil = [];
        console.log("err", error);
      }
    },
  },
};
</script>
<style scoped type="scss">
@import url("https://unpkg.com/vue-select@3.20.2/dist/vue-select.css");
</style>
